import React, { Suspense, lazy } from 'react';
import './App.css';
import 'antd/dist/reset.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import Loading from '../src/components/loading';
import AdminLogin from './view/AdminLogin';

// auth
const OperatorLogin = lazy(() => import('./view/OperatorLogin.js'));

const RegisterPage = lazy(() => import('./view/RegisterPage.js'));

const SelectOutlets = lazy(() => import('./view/SelectOutlets.js'));

const ConfigurePage = lazy(() => import('./view/ConfigurePage.js'));
const UserManagementPage = lazy(() => import('./view/UserManagementPage.js'));
const GlobalConfigPage = lazy(() => import('./view/GlobalConfigPage.js'));
const MaintenanceLogPage = lazy(() => import('./view/MaintenanceLog.js'));
const OfflineLogPage = lazy(() => import('./view/OfflineLog.js'));

function App() {
  return (
    <div className="App">
      <Suspense fallback={<Loading />}>
        <Router>
          <Routes>
            <Route exact path="/login" element={<OperatorLogin />} />
            <Route exact path="/admin/login" element={<AdminLogin />} />

            <Route exact path="/register" element={<RegisterPage />} />
            <Route exact path="/selectOutlet" element={<SelectOutlets />} />
            <Route exact path="/config" element={<ConfigurePage />} />
            <Route
              exact
              path="/user/management"
              element={<UserManagementPage />}
            />

            <Route exact path="/global/config" element={<GlobalConfigPage />} />
            <Route exact path="/maintenance/log" element={<MaintenanceLogPage />} />
            <Route exact path="/offline/log" element={<OfflineLogPage />} />

            <Route path="*" element={<Navigate to="/login" />}></Route>
          </Routes>
        </Router>
      </Suspense>
    </div>
  );
}

export default App;
